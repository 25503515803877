import {useQuery, useQueryClient} from '@tanstack/react-query'
import {WS_ORDER} from './Order.socket'
import {createSelector} from '@reduxjs/toolkit'
import {BaseResponse, Pagination} from 'src/app/models/api.types'
import {OrderModel} from './Order.model'
import {createContext, useContext} from 'react'
import {useWs, WsPayload} from 'src/app/hooks/ws-hook'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from 'src/setup'
import {SettingsModel} from 'src/app/modules/Settings/Settings.types'
import { useGetOrderBroadcast } from './Order.order-broadcast.provider'

const OrderContext = createContext<any>(null)

export const OrderProvider = ({children}: any) => {
  const settings = useSelector<RootState>(({settings}) => settings, shallowEqual) as SettingsModel
  const orderBroadcast = useGetOrderBroadcast()
  const payload: WsPayload = {
    type: 'POST',
    data: {
      visit_purpose_id_list: settings.visit_purpose_id,
      station_id_list: settings.stations_id,
    },
  }

  const value = useWs({
    url: WS_ORDER,
    payload,
    dependencies: orderBroadcast?.dataUpdatedAt ?? {}
  })

  return <OrderContext.Provider value={value}>{children}</OrderContext.Provider>
}

// Define a custom hook to access the chat messages context
export const useOrderContext = () => useContext(OrderContext)

export const useGetOrder = () => {
  return useQuery({
    queryKey: [WS_ORDER],
    queryFn: () => ({}),
    staleTime: Infinity,
    cacheTime: Infinity,
    keepPreviousData: false,
    select: createSelector(
      (state: BaseResponse<Pagination<OrderModel[]>>) => state.response_output?.list?.content,
      (data) => data
    ),
  })
}

export const useRefetchOrder = (id?: string) => {
  const {refetch} = useOrderContext()
  const queryClient = useQueryClient()

  return () => {
    refetch()
    queryClient.invalidateQueries({queryKey: [WS_ORDER]})
  }
}
