import {createContext, useCallback, useContext, useEffect, useMemo} from 'react'
import {SettingsModel} from './Settings.types'
import {useDispatch} from 'react-redux'
import SettingsRedux from './Settings.redux'
import {getKDSSettings, saveKDSSettings} from './Settings.services'

const SettingsContext = createContext<any>(null)

export const SettingsProvider = ({children}: any) => {
  const dispatch = useDispatch()

  const getKdsSettings = useCallback(() => {
    getKDSSettings()
      .then((res) => {
        dispatch(SettingsRedux.actions.Update(res.data.response_output.detail))
      })
      .catch((err) => {})
      .then(() => {})
  }, [dispatch])

  const saveKdsSettings = useCallback(
    (payload: SettingsModel) => {
      saveKDSSettings(payload)
        .then((res) => {
          if (res.status === 200) dispatch(SettingsRedux.actions.Update(res.data.response_output.detail))
        })
        .catch((err) => {})
        .finally(() => {})
    },
    [dispatch]
  )

  useEffect(() => {
    getKdsSettings()
  }, [getKdsSettings])

  const value = useMemo(
    () => ({
      getKdsSettings,
      saveKdsSettings,
    }),
    [getKdsSettings, saveKdsSettings]
  )

  return <SettingsContext.Provider value={value}>{children}</SettingsContext.Provider>
}

// Define a custom hook to access the chat messages context
export const useSettingsContext = () => useContext(SettingsContext)
