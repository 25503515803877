import {useFormik} from 'formik'
import {GeneratedFormProps} from 'src/app/components/Libs'
import SettingsMultipleCheckbox from './Settings.multiple-checkbox'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from 'src/setup'
import {SettingsModel} from './Settings.types'
import {useSettingsContext} from './Settings.provider'
import {SettingsFormProps} from './Settings.form'
import {useHeaderToast} from 'src/app/context/ToastContext'
import {
  useGetVisitPurpose,
  useVisitPurposeContext,
} from 'src/app/services/visit-purpose/VisitPurpose.provider'
import {useGetStation, useStationContext} from 'src/app/services/station/Station.provider'
import {useGetViewMode, useViewModeContext} from 'src/app/services/view-mode/ViewMode.provider'

export const useSettingsForm = (props: SettingsFormProps) => {
  const settings: any = useSelector<RootState>(({settings}) => settings, shallowEqual)
  const {addPageToasts} = useHeaderToast()
  const {saveKdsSettings} = useSettingsContext()
  const viewMode = useGetViewMode()
  const visitPurposeId = useGetVisitPurpose()
  const station = useGetStation()
  const viewModeContext = useViewModeContext()
  const visitPurposeContext = useVisitPurposeContext()
  const stationContext = useStationContext()

  const viewModeOptions = viewMode.data?.map((item) => ({label: item, value: item}))
  const visitPurposeIdOptions = visitPurposeId.data?.map((item) => ({
    label: item.name,
    value: item.id,
  }))
  const stationOptions = station.data?.map((item) => ({
    label: item.name,
    value: item.id,
  }))

  const initialValues: SettingsModel = {
    view_mode: settings?.view_mode,
    visit_purpose_id: settings?.visit_purpose_id,
    time_first_warning: settings?.time_first_warning,
    time_second_warning: settings?.time_second_warning,
    stations_id: settings?.stations_id,
    printing_station_id: settings?.printing_station_id,
  }

  const submitFn = (values: SettingsModel) => {
    saveKdsSettings(values)
    props?.handleClose?.()
    addPageToasts({
      scheme: 'success',
      text: 'Settings successfully saved',
    })
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: (values) => {
      submitFn(values)
    },
  })

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault()
    }
  }

  const handleCopy = (e: any) => {
    e.preventDefault()
    return false
  }

  const handlePaste = (e: any) => {
    e.preventDefault()
    return false
  }

  const generatedForm: GeneratedFormProps[] = [
    {
      className: 'grid md:grid grid-cols-12 md:space-x-0 space-y-8 items-start w-full',
      value: [
        {
          title: 'View Mode',
          value: 'view_mode',
          placeholder: 'Enter View Mode',
          type: 'select',
          options: viewModeOptions,
          className: 'col-span-12',
        },
        {
          className: 'col-span-12',
          renderForm: (
            <SettingsMultipleCheckbox
              title='Visit Purpose(s)'
              options={visitPurposeIdOptions}
              value={formik.values.visit_purpose_id}
              setValue={(value?: number[]) => formik.setFieldValue('visit_purpose_id', value)}
            />
          ),
        },
        {
          title: 'Time First Warning',
          value: 'time_first_warning',
          placeholder: 'Enter Time First Warning',
          type: 'text',
          className: 'col-span-12',
          onKeyPress: handleKeyPress,
          onCopy: handleCopy,
          onPaste: handlePaste,
        },
        {
          title: 'Time Second Warning',
          value: 'time_second_warning',
          placeholder: 'Enter Time Second Warning',
          type: 'text',
          className: 'col-span-12',
          onKeyPress: handleKeyPress,
          onCopy: handleCopy,
          onPaste: handlePaste,
        },
        {
          className: 'col-span-12',
          renderForm: (
            <SettingsMultipleCheckbox
              title='Station(s)'
              options={stationOptions}
              value={formik.values.stations_id}
              setValue={(value?: number[]) => formik.setFieldValue('stations_id', value)}
            />
          ),
        },
        {
          className: 'col-span-12',
          renderForm: (
            <SettingsMultipleCheckbox
              title='Printing Station(s)'
              options={stationOptions}
              value={formik.values.printing_station_id}
              setValue={(value?: number[]) => formik.setFieldValue('printing_station_id', value)}
            />
          ),
        },
      ],
    },
  ]

  const handleCancel = () => {
    props?.handleClose?.()
  }

  const handleCheckForUpdate = () => {
    viewModeContext.refetch()
    visitPurposeContext.refetch()
    stationContext.refetch()
  }

  return {
    formik,
    generatedForm,
    submitFn,
    action: {
      handleCancel,
      handleCheckForUpdate,
    },
  }
}
