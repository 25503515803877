import axios from 'axios'
import {webSocketUrl} from 'src/app/utils/ws-utils'
import {SettingsModel} from './Settings.types'

const url = `http://${webSocketUrl?.replace('ws://', '').replaceAll('9002', '9003')}/kds/settings`

export const getKDSSettings = () => {
  return axios.get(url)
}

export const saveKDSSettings = (payload: SettingsModel) => {
  return axios.put(url, payload)
}
